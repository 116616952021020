<template>
  <div class="Adviser">
    <div class="adviser_top" ref="scrollview" @mousewheel="scrollChange">
      <div v-for="(item, index) in chatrecord" :key="index">
        <!-- <div>{{ item.createTime }}</div> -->
        <div class="adviser_top_left" v-if="item.systemUserId == null">
          <img :src="item.headImgUrl" alt="" />
          <div class="adviser_top_right_item">
            <div class="adviser_top_right_item_txt" v-if="item.contentType == '1'">
              <span>{{ item.content }}</span>
            </div>
            <div class="adviser_top_right_item_aac" v-if="item.contentType == '2'">
              <div>
                <!-- <span>{{ item.voiceSeconds }}"</span>
                -->
                <audio controls="controls">
                  <source :src="item.content" type="audio/mpeg" />
                </audio>
              </div>
            </div>
            <div class="adviser_top_right_item_img" v-if="item.contentType == '3'">
              <img style="border-radius: 0px;" :src="item.content" alt="" />
            </div>
          </div>
        </div>
        <div class="adviser_top_right" v-else>
          <div class="adviser_top_right_item">
            <div class="adviser_top_right_item_txt" style="padding: 1px 10px">
              <span v-html="item.content"></span>
            </div>
          </div>
          <img :src="item.headImgUrl" alt="" />
        </div>
      </div>
    </div>
    <div class="adviser_top_editor">
      <editor v-model="sendparam.content"> </editor>
    </div>

    <div slot="footer" class="footer">
      <el-button type="primary" @click="sendMessage">回复</el-button>
    </div>
  </div>
</template>

<script>
import { Pagedetail, sendMessage } from '@/api/adviser.js'
import editor from '../../../../components/Editorbar.vue'
export default {
  components: { editor },
  name: 'Adviser',
  data() {
    return {
      param: {
        userId: this.$route.query.id,
        pageNumber: 1,
        pageSize: 10
      },
      sendparam: {
        content: '',
        contentType: '1',
        systemUserId: null,
        userId: this.$route.query.id
      },
      chatrecord: [],
      total: 0 //总条
    }
  },
  created() {
    this.search()
  },
  mounted() {
    // 获取指定元素
    const scrollview = this.$refs['scrollview']
    // 添加滚动监听，该滚动监听了拖拽滚动条
    // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
    scrollview.addEventListener('scroll', this.scrollChange, true)
  },
  // beforeDestroy 与 destroyed 里面移除都行
  beforeDestroy() {
    // 获取指定元素
    const scrollview = this.$refs['scrollview']
    // 移除监听
    scrollview.removeEventListener('scroll', this.scrollChange, true)
  },
  methods: {
    async search() {
      const { data: res } = await Pagedetail(this.param)
      if (res.resultCode === 200) {
        this.total = res.data.total
       
          this.chatrecord = res.data.list.reverse()
          this.$nextTick(() => {
            this.$refs.scrollview.scrollTop = this.$refs.scrollview.scrollHeight
          })
        
      }
    },
    async sendMessage() {
      const userId = sessionStorage.getItem('userId')
      this.sendparam.systemUserId = userId

      const { data: res } = await sendMessage(this.sendparam)
      if (res.resultCode === 200) {
        this.sendparam.content=''
        this.param.pageNumber=1
        this.search()
      }
    },

    // 滚动监听
    scrollChange(e) {
      const topheight = this.$refs.scrollview.scrollTop
      var num = this.param.pageNumber * this.param.pageSize
      if (topheight == 0 && this.total > num) {
        this.param.pageNumber = this.param.pageNumber + 1
       
        Pagedetail(this.param).then((response) => {
          console.log(response);
          if (response.data.resultCode == 200) {
            var list=response.data.data.list.reverse()
          this.chatrecord.splice(0,0,...list)
          }
        })
      }
    },
    //去重
    removal(arr) {
      let newArr = []
      let obj = {}
      for (var i = 0; i < arr.length; i++) {
        if (!obj[arr[i].id]) {
          newArr.push(arr[i])
          obj[arr[i].id] = true
        }
      }
      return newArr
    },
  
  }
}
</script>

<style lang="less" scoped>
.Adviser {
  .adviser_top {
    padding: 28px;
    height: 308px;
    display: flex;
    flex-direction: column;
    background: #fff;
    overflow-x: hidden;
    overflow-y: scroll;
    .adviser_top_left {
      overflow: hidden;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      img {
        width: 52px;
        height: 52px;
        border-radius: 52px;
      }
    }
    .adviser_top_right {
      overflow: hidden;
      float: right;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      img {
        width: 52px;
        height: 52px;
        border-radius: 52px;
      }
    }
    .adviser_top_right_item {
      margin-left: 15px;
      .adviser_top_right_item_txt {
        font-size: 14px;
        padding: 15px 10px 15px 12px;
        background: #f6f6f6;
      }
      .adviser_top_right_item_img {
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .adviser_top_editor {
    margin-top: 20px;
    background: #fff;
    padding: 30px;
  }
  .footer {
    display: flex;
    justify-content: center;
    padding: 10px 0px 30px 0px;
    background: #fff;
  }
}
::v-deep .editor .text {
  border: 1px solid #ccc;
  min-height: 255px;
  max-height: 1055px;
  background: #fff;
}
</style>
